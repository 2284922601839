import React from "react";
var __jsx = React.createElement;
import Moment from 'react-moment';

// The Timezone is based on the device and not on the salon
import { dateInSalonTimezone } from '~/common/Localize';
var BranchTime = function BranchTime(props) {
  var children = props.children;
  var time = dateInSalonTimezone(children, Moment.globalLocale);
  return (
    // @ts-ignore
    __jsx(Moment, {
      format: 'LT',
      testID: 'branchTime'
    }, time)
  );
};
export default BranchTime;