import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
import React from "react";
var __jsx = React.createElement;
import Localize from '~/common/Localize';
import { Container, SubTitle2, Theme, themed } from '~/styles/Theme';
var GroupBookingBanner = function GroupBookingBanner() {
  return __jsx(GroupBookingBadge, {
    testID: "groupBookingBadge"
  }, __jsx(GroupBookingBadgeText, null, Localize('appointment.groupBooking')));
};
export default GroupBookingBanner;
var GroupBookingBadge = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  padding: 4px 8px\n  border-radius: 16px\n  height: 26px\n  alignSelf: flex-start\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.info, "15");
});
var GroupBookingBadgeText = themed(SubTitle2)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: ", "\n  font-size: 10px\n"])), function (props) {
  return Theme(props).colors.black;
});