import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { appointmentCancellation as appointmentCancellationResource, serviceHistories as serviceHistoriesResource, user as userResource } from '~/api/Resources';
import Localize from '~/common/Localize';
import { queryClient } from '~/common/Provider';
import { AnalyticsEvents } from '~/common/analytics/Analytics';
import useAnalytics from '~/common/hooks/analytics/useAnalytics';
import { fetchResource } from '~/common/network/network';
import { selectBranchId } from '~/common/slices/app.slice';
import { addCartItems, setRescheduleReservation, startNewCart } from '~/common/slices/cart.slice';
import { addClients } from '~/common/slices/client.slice';
import { selectBranch } from '~/common/slices/model.slice';
import { selectIsUserLoggedIn, selectToken } from '~/common/slices/user.slice';
import { Alert } from '~/components/Alert';
import { onLinkPress } from '~/components/OpenLink';
import { RouteName, getRouteUrl } from '~/navigation/Routes';
import { buildCancelAppointmentRequest, buildSelectedServices, checkIfAppointmentCanBeCancelled, findNextAppointmentInDay, pickStaffAlert } from './AppointmentHistoryViewModel';
export var HISTORY_PAGE_SIZE = 8;
var ORDER_PARAMS = {
  ASC: '+date',
  DESC: '-date'
};
function _getNextPageParam(lastPage, pages, pageSize) {
  // If the last page has fewer items than the max size, we assume there are no more pages to fetch
  if (lastPage.serviceHistories.length < pageSize) {
    return undefined;
  }

  // Calculate the next start value based on the current number of pages and the page size
  return pages.length * pageSize;
}
export default function useAppointmentHistory() {
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var dispatch = useDispatch();
  var isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  var branchId = useSelector(selectBranchId);
  var thisBranch = useSelector(selectBranch);
  var token = useSelector(selectToken);
  var analytics = useAnalytics();
  var todayDate = useMemo(function () {
    return moment().format('YYYY-MM-DD');
  }, []);

  // Need to add a key here to refresh the list after an appointment is booked, rescheduled or cancelled
  var _useInfiniteQuery = useInfiniteQuery(['futureServiceHistories', branchId], function (_ref) {
      var _ref$pageParam = _ref.pageParam,
        pageParam = _ref$pageParam === void 0 ? 0 : _ref$pageParam;
      return fetchResource(serviceHistoriesResource, {
        start_date: todayDate,
        start: pageParam,
        max: HISTORY_PAGE_SIZE,
        order: ORDER_PARAMS.ASC
      });
    }, {
      retry: false,
      enabled: isUserLoggedIn,
      cacheTime: 0,
      staleTime: 0,
      getNextPageParam: function getNextPageParam(lastPage, pages) {
        return _getNextPageParam(lastPage, pages, HISTORY_PAGE_SIZE);
      }
    }),
    futureAppointmentsData = _useInfiniteQuery.data,
    isFutureAppointmentsLoading = _useInfiniteQuery.isLoading,
    isFutureAppointmentsError = _useInfiniteQuery.isError,
    refetchFutureAppointments = _useInfiniteQuery.refetch,
    fetchFutureAppointmentsNextPage = _useInfiniteQuery.fetchNextPage,
    hasMoreFutureAppointments = _useInfiniteQuery.hasNextPage;
  var _useInfiniteQuery2 = useInfiniteQuery(['pastServiceHistories', branchId], function (_ref2) {
      var _ref2$pageParam = _ref2.pageParam,
        pageParam = _ref2$pageParam === void 0 ? 0 : _ref2$pageParam;
      return fetchResource(serviceHistoriesResource, {
        end_date: todayDate,
        start: pageParam,
        max: HISTORY_PAGE_SIZE,
        order: ORDER_PARAMS.DESC
      });
    }, {
      retry: false,
      enabled: isUserLoggedIn,
      cacheTime: 0,
      staleTime: 0,
      getNextPageParam: function getNextPageParam(lastPage, pages) {
        return _getNextPageParam(lastPage, pages, HISTORY_PAGE_SIZE);
      }
    }),
    pastAppointmentsData = _useInfiniteQuery2.data,
    isPastAppointmentsLoading = _useInfiniteQuery2.isLoading,
    isPastAppointmentsError = _useInfiniteQuery2.isError,
    refetchPastAppointments = _useInfiniteQuery2.refetch,
    fetchPastAppointmentsNextPage = _useInfiniteQuery2.fetchNextPage,
    hasMorePastAppointments = _useInfiniteQuery2.hasNextPage;
  var futureAppointments = useMemo(function () {
    return futureAppointmentsData === null || futureAppointmentsData === void 0 ? void 0 : futureAppointmentsData.pages.flatMap(function (page) {
      return page.serviceHistories;
    });
  }, [futureAppointmentsData]);
  var pastAppointments = useMemo(function () {
    return pastAppointmentsData === null || pastAppointmentsData === void 0 ? void 0 : pastAppointmentsData.pages.flatMap(function (page) {
      return page.serviceHistories;
    });
  }, [pastAppointmentsData]);
  var _useMutation = useMutation(function (appointment) {
      var request = buildCancelAppointmentRequest(appointment);
      return fetchResource(appointmentCancellationResource, {}, request);
    }, {
      retry: false,
      onError: function onError(error) {
        if (error.errorCode === 'CANCELLED_APPOINTMENT_CANNOT_REFUND_DEPOSIT') {
          Alert.alert(Localize('appointment.cancelErrorRefundTitle'), Localize('appointment.cancelErrorRefundCopy'), [{
            text: Localize('appointment.call'),
            onPress: function onPress() {
              return onLinkPress("tel:".concat(thisBranch === null || thisBranch === void 0 ? void 0 : thisBranch.phone));
            }
          }]);
        } else {
          Alert.alert(Localize('appointment.cancelError1'), Localize('appointment.cancelError2'), [{
            text: Localize('global.ok')
          }]);
        }
        console.warn('An error occurred while cancelling a booking  ' + JSON.stringify(error));
      },
      onSuccess: function onSuccess() {
        Alert.alert(Localize('appointment.cancelled'), '', [{
          text: Localize('global.ok')
        }]);
        refetchFutureAppointments();
        queryClient.invalidateQueries({
          queryKey: [userResource.path, branchId, token]
        }); // In case this cancellation causes a change in available reward points
      }
    }),
    mutate = _useMutation.mutate,
    isLoadingMutation = _useMutation.isLoading;
  function startNewSession(appointment, ignoreRewards, anyStaff) {
    var selectedServices = buildSelectedServices(appointment, ignoreRewards, anyStaff);
    dispatch(startNewCart({
      isInRescheduleOrRebookFlow: true
    }));
    dispatch(addCartItems(selectedServices));
    if (appointment.groupBookingNumberOfClients) {
      dispatch(addClients({
        numberOfClients: appointment.groupBookingNumberOfClients - 1,
        cartItemIds: selectedServices.map(function (cartItem) {
          return cartItem.id;
        })
      }));
    }
  }
  function navigateToReschedule(selectedAppointment) {
    // in case of group booking the staff is set to any since API don't support yet reschedule
    // with specific staff
    startNewSession(selectedAppointment, false, selectedAppointment.isGroupBooking);
    var reservation = futureAppointments === null || futureAppointments === void 0 ? void 0 : futureAppointments.find(function (serviceHistory) {
      return serviceHistory.id === selectedAppointment.id;
    });
    dispatch(setRescheduleReservation(reservation));
    push(getRouteUrl(RouteName.Availability, {
      reschedule: reservation != null
    }));
  }
  function onRebookPress(selectedAppointment, rebookSource) {
    analytics.logAnalyticsEvent(rebookSource);
    if (selectedAppointment.isGroupBooking) {
      startNewSession(selectedAppointment, true, true);
      push(getRouteUrl(RouteName.Availability));
    } else {
      pickStaffAlert(function (anyStaff) {
        startNewSession(selectedAppointment, true, anyStaff);
        push(getRouteUrl(RouteName.Availability));
      });
    }
  }
  function onReschedulePress(item, branch) {
    analytics.logAnalyticsEvent(AnalyticsEvents.RescheduleFromAppointmentsListStart);
    var nextAppointment = findNextAppointmentInDay(item);
    if (!nextAppointment) {
      console.warn('Inconsistency, next Appointment should always exist when trying to cancel');
      return;
    }
    if (branch.cancellationPolicyHours !== undefined) {
      var canAppointmentBeCancelled = checkIfAppointmentCanBeCancelled(branch.cancellationPolicyHours, nextAppointment.time);
      if (!canAppointmentBeCancelled && branch.phone) {
        return Alert.alert(branch.phone, "".concat(Localize('appointment.rescheduleErrorAlert1'), " ").concat(branch.cancellationPolicyHours, " ").concat(Localize('appointment.rescheduleErrorAlert2')), [{
          text: Localize('appointment.nevermind'),
          style: 'cancel'
        }, {
          text: Localize('appointment.call'),
          onPress: function onPress() {
            return onLinkPress("tel:".concat(branch.phone));
          }
        }]);
      }
      if (!canAppointmentBeCancelled) {
        return Alert.alert("".concat(Localize('appointment.rescheduleErrorAlert1'), " ").concat(branch.cancellationPolicyHours, " ").concat(Localize('appointment.rescheduleErrorAlert2')), '', [{
          text: Localize('global.ok')
        }]);
      }
      return Alert.alert(Localize('appointment.rescheduleAlert'), item.isGroupBooking ? Localize('appointment.rescheduleAlertGroupBooking') : '', [{
        text: Localize('appointment.nevermind'),
        style: 'cancel'
      }, {
        text: Localize('appointment.reschedule'),
        onPress: function onPress() {
          return navigateToReschedule(item);
        }
      }]);
    }
  }
  function onCancelPress(item, branch) {
    analytics.logAnalyticsEvent(AnalyticsEvents.CancelAppointment);
    var nextAppointment = findNextAppointmentInDay(item);
    if (!nextAppointment) {
      console.warn('Inconsistency, next Appointment should always exist when trying to cancel');
      return;
    }
    if (branch.cancellationPolicyHours !== undefined) {
      var canAppointmentBeCancelled = checkIfAppointmentCanBeCancelled(branch.cancellationPolicyHours, nextAppointment.time);
      if (!canAppointmentBeCancelled && branch.phone) {
        return Alert.alert(branch.phone, "".concat(Localize('appointment.callAlert1'), " ").concat(branch.cancellationPolicyHours, " ").concat(Localize('appointment.callAlert2')), [{
          text: Localize('appointment.nevermind'),
          style: 'cancel'
        }, {
          text: Localize('appointment.call'),
          onPress: function onPress() {
            return onLinkPress("tel:".concat(branch.phone));
          }
        }]);
      }
      if (!canAppointmentBeCancelled) {
        return Alert.alert("".concat(Localize('appointment.callAlert1'), " ").concat(branch.cancellationPolicyHours, " ").concat(Localize('appointment.callAlert2')), '', [{
          text: Localize('global.ok')
        }]);
      }
      return Alert.alert(Localize('appointment.cancelAlert1'), Localize('appointment.cancelAlert2'), [{
        text: Localize('appointment.nevermind'),
        style: 'cancel'
      }, {
        text: Localize('appointment.cancelConfirm'),
        onPress: function onPress() {
          return mutate(item);
        }
      }]);
    }
  }
  return {
    onRebookPress: onRebookPress,
    onReschedulePress: onReschedulePress,
    onCancelPress: onCancelPress,
    futureAppointments: futureAppointments,
    pastAppointments: pastAppointments,
    isLoading: isFutureAppointmentsLoading || isPastAppointmentsLoading || isLoadingMutation,
    isError: isFutureAppointmentsError || isPastAppointmentsError,
    refetchFutureAppointments: refetchFutureAppointments,
    refetchPastAppointments: refetchPastAppointments,
    fetchFutureAppointmentsNextPage: fetchFutureAppointmentsNextPage,
    fetchPastAppointmentsNextPage: fetchPastAppointmentsNextPage,
    hasMoreFutureAppointments: hasMoreFutureAppointments,
    hasMorePastAppointments: hasMorePastAppointments
  };
}