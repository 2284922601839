import moment from 'moment';
import { ActivationStateEnum } from '~/api/models/BookingTypes';
import { CartFunctions } from '~/api/models/CartFunctions';
import Localize, { getGlobalLocale } from '~/common/Localize';
import { Alert } from '~/components/Alert';
export function buildCancelAppointmentRequest(appointment) {
  return {
    date: appointment.date
  };
}
export function findNextAppointmentInDay(item) {
  var itemDueToStart = item.serviceItems.find(function (serviceItem) {
    return new Date(serviceItem.time) > new Date();
  });
  return itemDueToStart;
}
export function rescheduleList(item) {
  item.serviceItems = item.serviceItems.filter(function (detail) {
    return detail.cancelled === false;
  });
  return item;
}
export function hasAtLeastOneUpcomingAppointment(item) {
  var i = 0;
  while (i < item.details.length) {
    if (item.details[i].cancelled === false) {
      return true;
    }
    i++;
  }
  return false;
}

// TODO: This information should be part of the view model rather than an external function
export function isServiceReBookable(appointment) {
  return appointment.serviceItems.find(function (item) {
    return item.isServiceArchived || item.isStaffArchived || item.isHiddenFromOnlineBooking || !item.internetEnabled || item.clientCourseItemId;
  }) === undefined;
}
export function findUpcomingAppointments(appointmentHistories) {
  return appointmentHistories.filter(function (item) {
    var itemDate = new Date(item.details[item.details.length - 1].time);
    return itemDate.getTime() >= new Date().getTime() && hasAtLeastOneUpcomingAppointment(item);
  });
}
export function findPastAppointments(appointmentHistories) {
  return appointmentHistories.filter(function (item) {
    if (!hasAtLeastOneUpcomingAppointment(item)) {
      return true;
    }
    var itemDate = new Date(item.details[item.details.length - 1].time);
    return itemDate.getTime() < new Date().getTime();
  });
}
export function shouldDisplayDepositBanner(appointmentHistories, customLocale) {
  var locale = customLocale ? customLocale : getGlobalLocale();
  return appointmentHistories.length > 0 && locale.toLowerCase().includes('en') && !locale.toLowerCase().includes('us');
}
export function buildAppointmentViewModel(appointmentHistories) {
  var appointmentHistoryViewModels = appointmentHistories.map(function (appointment) {
    var _serviceItems$find;
    var date = appointment.details[0].time;
    var id = appointment.id;
    var cancelled = appointment.details.every(function (detail) {
      return detail.cancelled;
    });
    var serviceItems = appointment.details.map(function (detail) {
      return {
        id: detail.service.id,
        serviceName: detail.service.name,
        staffName: detail.staff.firstName,
        time: detail.time,
        cancelled: detail.cancelled,
        isServiceArchived: detail.service.isArchived,
        isStaffArchived: detail.staff.isArchived,
        internetEnabled: detail.service.internetEnabled,
        isHiddenFromOnlineBooking: detail.staff.hiddenFromOnlineBookings,
        service: detail.service,
        serviceGroupId: detail.serviceGroupId,
        staff: detail.staff,
        fee: detail.fee,
        clientCourseItemId: detail.clientCourseItemId,
        serviceRewardId: detail.serviceRewardId,
        groupBookingId: detail.groupBookingId,
        groupBookingNumberOfClients: detail.groupBookingNumberOfClients
      };
    });
    return {
      id: id,
      date: date,
      isGroupBooking: serviceItems.some(function (item) {
        return item.groupBookingId != null;
      }),
      groupBookingNumberOfClients: (_serviceItems$find = serviceItems.find(function (item) {
        return item.groupBookingId != null;
      })) === null || _serviceItems$find === void 0 ? void 0 : _serviceItems$find.groupBookingNumberOfClients,
      serviceItems: serviceItems,
      cancelled: cancelled
    };
  });
  return appointmentHistoryViewModels;
}
export function buildAppointmentGroupViewModel(appointments, serviceItems) {
  return appointments.map(function (appointment) {
    var _serviceItems$find2;
    return {
      id: appointment.service.id,
      serviceName: appointment.service.name,
      staffName: appointment.staff.firstName,
      time: appointment.startTime,
      isServiceArchived: appointment.service.isArchived,
      isStaffArchived: appointment.staff.isArchived,
      internetEnabled: appointment.service.internetEnabled,
      isHiddenFromOnlineBooking: appointment.staff.hiddenFromOnlineBookings,
      cancelled: appointment.activationState === ActivationStateEnum.CANCELLED,
      service: appointment.service,
      serviceGroupId: appointment.serviceGroupId,
      staff: appointment.staff,
      groupBookingId: appointment.groupBookingId,
      serviceRewardId: (_serviceItems$find2 = serviceItems.find(function (item) {
        return item.id === appointment.service.id;
      })) === null || _serviceItems$find2 === void 0 ? void 0 : _serviceItems$find2.serviceRewardId
    };
  });
}
export function checkIfAppointmentCanBeCancelled(cancellationPolicyHours, nearestAppointment) {
  var bufferTime = moment(new Date()).add(cancellationPolicyHours, 'hours');
  return bufferTime.toDate() < new Date(nearestAppointment);
}
export function pickStaffAlert(_onPress) {
  return Alert.alert(Localize('appointment.whichStaff'), '', [{
    text: Localize('appointment.sameStaff'),
    onPress: function onPress() {
      _onPress(false);
    },
    style: 'default'
  }, {
    text: Localize('appointment.anyStaff'),
    onPress: function onPress() {
      _onPress(true);
    },
    style: 'destructive'
  }, {
    text: Localize('appointment.nevermind'),
    style: 'cancel'
  }]);
}
export function buildSelectedServices(appointment, ignoreRewards, anyStaff) {
  return appointment.serviceItems.map(function (item) {
    return CartFunctions.createServiceHistoryCartItem(item.service, item.serviceGroupId, ignoreRewards ? undefined : item.serviceRewardId, anyStaff ? undefined : item.staff);
  });
}